  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline;
    margin: 0;
    padding: 0;
    display: inline-block;
    padding: 10px 22px;
    min-height: 1px;
    line-height: 1.3em;
    vertical-align: middle;
    position: relative;
    z-index: 597;
    font-family: Calibri, Tahoma, Arial, sans-serif;
    font-size: 14px;
    color: #ffffff;

  }
  
  button:hover{
    background-color: #3fa338;
  }
  button:focus {
  text-decoration: none;
  }
  button:visited {
    color: #9a9a9a;
    text-decoration: none;
  }

  
  /* Base Styles */
  #nav > ul,
  #nav > ul li,
  #nav > ul ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  #nsv > ul {
    position: relative;
    z-index: 597;
  }
  #nav > ul li {
    float: left;
    position: relative;
    width: auto;
    min-height: 1px;
    line-height: 1.3em;
    vertical-align: middle;
  }
  #nav > ul li.hover,
  #nav > ul li:hover {
    position: relative;
    z-index: 599;
    cursor: default;
  }
  #nav > ul ul {
    visibility: hidden;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 598;
    width: 100%;
  }
  #nav > ul ul li {
    float: none;
  }
  #nav > ul ul ul {
    top: 1px;
    left: 99%;
  }
  #nav > ul li:hover > ul {
    visibility: visible;
  }
  /* Align last drop down RTL */
  #nav > ul > li.last ul ul {
    left: auto !important;
    right: 99%;
  }
  #nav > ul > li.last ul {
    left: auto;
    right: 0;
  }
  #nav > ul > li.last {
    text-align: right;
  }
  /* Theme Styles */
  #nav > ul {
    border-top: 4px solid #3fa338;
    font-family: Calibri, Tahoma, Arial, sans-serif;
    font-size: 14px;
    background: #1e1e1e;
    background: -moz-linear-gradient(top, #1e1e1e 0%, #040404 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #1e1e1e), color-stop(100%, #040404));
    background: -webkit-linear-gradient(top, #1e1e1e 0%, #040404 100%);
    background: to left(top, #1e1e1e 0%, #040404 100%);
    width: auto;
    margin: 0 0 3em 0;
    padding: 0;
    list-style: none;
    zoom: 1;
  }
  #nav > ul:before {
    content: '';
    display: block;
  }
  #nav > ul:after {
    content: '';
    display: table;
    clear: both;
  }
  #nav > ul li a {
    display: inline-block;
    padding: 10px 22px;
  }
  #nav > ul > li.active,
  #nav > ul > li.active:hover {
    background-color: #3fa338;
  }
  #nav > ul > li > a:link,
  #nav > ul > li > a:active,
  #nav > ul > li > a:visited {
    color: #ffffff;
  }
  #nav > ul > li > a:hover {
    color: #ffffff;
  }
  #nav > ul ul ul {
    top: 0;
  }
  #nav > ul li li {
    background-color: #ffffff;
    border-bottom: 1px solid #ebebeb;
    font-size: 12px;
  }
  #nav > ul li.hover,
  #nav > ul li:hover {
    background-color: #F5F5F5;
  }
  #nav > ul > li.hover,
  #nav > ul > li:hover {
    background-color: #3fa338;
    -webkit-box-shadow: inset 0 -3px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: inset 0 -3px 0 rgba(0, 0, 0, 0.15);
    box-shadow: inset 0 -3px 0 rgba(0, 0, 0, 0.15);
  }
  #nav > ul a:link,
  #nav > ul a:visited {
    color: #9a9a9a;
    text-decoration: none;
  }
  #nav > ul a:hover {
    color: #9a9a9a;
  }
  #nav > ul a:active {
    color: #9a9a9a;
  }
  #nav > ul ul {
    border: 1px solid #CCC \9;
    -webkit-box-shadow: 0 0px 2px 1px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 0px 2px 1px rgba(0, 0, 0, 0.15);
    box-shadow: 0 0px 2px 1px rgba(0, 0, 0, 0.15);
    width: 150px;
  }
  
  