.divider{
    width:30px;
    height:auto;
    display:inline-block;
}

.btn-success{
    background-color: #3fa338 !important;
    border-color: #3fa338 !important;
}
  
.button {
    background-color: #000000;
    color: #ffffff;
    text-decoration: none;
    display: inline-block;
    padding: 8px 16px;
}

button:hover{
    background-color: #3fa338;
}

.btn-secondary {
    background-color: #000000!important;
    border-color: #000000!important;
    display: inline;
    margin: 0;
    padding: 0;
    display: inline-block;
    cursor:pointer;
    min-height: 38px;
    min-width: 130px;
    line-height: 1.3em;
    vertical-align: middle;
    position: relative;
    z-index: 597;
    font-family: Calibri, Tahoma, Arial, sans-serif;
    font-size: 14px;
    color: #ffffff!important;
}
.logos{
    max-width: 64px;
    max-height: 64px;
}
  
.btn-secondary:hover{
    display: block;
    background-color: #95a5a6!important;
    box-shadow:0 0 0 0.2rem rgba(rgba(127,140,141 ,1))!important;
}
.btn-secondary.focus{
    display: block;
    background-color: #95a5a6!important;
    box-shadow:0 0 0 0.2rem rgba(rgba(127,140,141 ,1))!important;
}

.noticeMessage{
    display: none;
    padding: 10px;
}